<template>
  <div class="course-list-page" style="padding-bottom: 80px;">
    <!-- DONE:面包屑导航 -->
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">{{
            $route.query.type == "button1"
            ? "行业要闻"
            : $route.query.type == "button2"
            ? "通知公告"
            : "行业要闻"
        }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="list-box" v-if="loading">
      <div class="bcon_left">
        <ul class="box-news">
          <div class="linkk" v-for="course in courseList" :key="course.id"   @click="$router.push(`/details?nid=${course.id}&tid=${course.type}`)">
            <li class="clears">
          <div class="bconli_left">
            <p>
              <span>{{ course.d}}</span>
              {{ course.ym }}
            </p>
          </div>
          <div class="bconli_right">
              <h2 class="title">{{ course.title }}</h2>
              <p v-text="course.newinfos"></p>
          </div>
            </li>
          </div>
        </ul>
      </div>
      
      <el-empty
        v-if="empty"
        :image="
          require(`../assets/images/${
            $route.query.tid ? 'no_course' : 'no_paper'
          }.png`)
        "
        :description="`暂无${$route.query.tid ? '课程' : '试卷'}`"
      ></el-empty>
      <el-pagination
        v-if="pageEmpty && totalNumber > 15"
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChangeList"
      >
      </el-pagination>
      <el-pagination
        v-if="getCourseAllShow && totalNumber > 15"
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChangeAll"
      >
      </el-pagination>
      <el-pagination
        v-if="recommendPage && totalNumber > 15"
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="changeRecommendPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Button } from 'element-ui';

export default {
  data() {
    return {
      typeName: "",
      totalNumber: 1,
      pageSize: 1,
      courseList: [],
      currentPage: 1, //默认
      getCourseListShow: false, //有id的课程列表
      getCourseAllShow: false, //全部课程列表
      empty: false,
      loading: false,
      pageEmpty: false,
      recommendPage: false,
    };
  },

  components: {},

  computed: {},
  mounted() {
    
    if (this.$route.query.tid) {
      this.getCourseList();
      this.getCourseListShow = true; //有id的课程列表
    } else if (this.$route.query.type) {
      this.moreRecommend();
    } else {
      this.getAllCourse();
      this.getCourseAllShow = true; //全部课程列表
    }
  },
  methods: {
    //获取有id的列表
    getCourseList() {
      this.typeName = this.$route.query.tn;
      let fd = new FormData();
      fd.append("type_id", this.$route.query.tid);
      fd.append("page_size", 15);
      this.axios.post("/index/course_list", fd).then((res) => {
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
        this.loading = true;
        if (this.totalNumber == 0) {
          this.empty = true;
        } else {
          this.pageEmpty = true;
        }
      });
    },
    //获取全部课程列表
    getAllCourse() {
      let fd = new FormData();
      fd.append("page_size", 15);
      this.axios.post("/index/course_list", fd).then((res) => {
        console.log(res);
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
        this.loading = true;
        if (this.totalNumber == 0) {
          this.empty = true;
        }
      });
    },
    // 各种跳转
    kindTo(courseId, ct) {
      if (!this.$route.query.tid && !this.$route.query.type) {
          this.$router.push(`/exercise?cid=${courseId}`); 
      } else {
        // if (this.is_vip == 0) {
        //   this.$message.error("请先开通会员才可以看此课程！");
        // } else {
          this.$router.push(
            `/courdetails?tid=${this.$route.query.tid}&cid=${courseId}&ct=${ct}&tn=${this.$route.query.tn}`
          );
        // }
      }
    },
    //更改页码有id
    handleCurrentChangeList(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("type_id", this.$route.query.tid);
      fd.append("page", page);
      fd.append("page_size", 15);

      this.axios.post("/index/course_list", fd).then((res) => {
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);

        this.courseList = res.data.list;
      });
    },
    //更改页码全部
    handleCurrentChangeAll(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 15);
      this.axios.post("/index/course_list", fd).then((res) => {
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
      });
    },
    // 更多推荐课程列表
    moreRecommend() {
      let fd = new FormData();
      fd.append("page_size", 15);
      if(this.$route.query.type == "button1"){
          this.axios.post("/index/more_news", fd).then((res) => {
          this.totalNumber = res.data.count;
          this.pageSize = Number(res.data.page_size);
          this.courseList = res.data.list;
          this.loading = true;
          if (res.data.count == 0) {
            this.empty = true;
            this.recommendPage = false;
          } else {
            this.recommendPage = true;
          }
        });
      }else{
        this.axios.post("/index/more_notice", fd).then((res) => {
          this.totalNumber = res.data.count;
          this.pageSize = Number(res.data.page_size);
          this.courseList = res.data.list;
          this.loading = true;
          if (res.data.count == 0) {
            this.empty = true;
            this.recommendPage = false;
          } else {
            this.recommendPage = true;
          }
        });
      }
      
    },
    changeRecommendPage(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 15);
      if(this.$route.query.type == "button1"){
          this.axios.post("/index/more_news", fd).then((res) => {
          this.totalNumber = res.data.count;
          this.pageSize = Number(res.data.page_size);
          this.courseList = res.data.list;
        });
      }else{
          this.axios.post("/index/more_notice", fd).then((res) => {
          this.totalNumber = res.data.count;
          this.pageSize = Number(res.data.page_size);
          this.courseList = res.data.list;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.course-list-page {
  min-height: calc(100vh - 70px);
  background-color: #f8f8f8;
  overflow: hidden;
  .list-box {

    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    text-align: left;
    background-color: #fff;
    box-sizing: border-box;
    .bcon_left{
      width: 900px;
      height: 100%;
      padding: 45px 150px 30px;
      background: #fff;
      float: left;
      padding-bottom: 90px;
    }
    .bconl_top{
      width: 100%;
      padding-bottom: 22px;
      border-bottom: 1px #d6d6d6 dashed;
    }
    .box-news{
      height: 140px;
      list-style: none;
      vertical-align: top;
    }
    .clears{
      zoom: 1;
    }
    .clears::after {
      content: ".";
      clear: both;
      display: block;
      overflow: hidden;
      font-size: 0;
      height: 0;
    }
   
    .linkk:hover{
      background-color: #f3f3f3;
      cursor: pointer;
    }
    ul li{
      padding: 20px 0;
      border-bottom: 1px #d6d6d6 solid;
      .bconli_left{
        float: left;
        width: 115px;
        height: 100px;
        background: #f3f3f3;
        margin-top: 8px;

        p{
          font-size: 16px;
          color: #424242;
          padding: 20px 0 0 20px;
        }
        span{
          margin-top: -19px;
          display: block;
          font-size: 26px;
          color: #d72502;
        }
      }
      .bconli_right {
        width: 740px;
        float: right;
        margin-right: -16px;
        h2{
          line-height: 5px;
          margin-top: 30px;
          margin-bottom: -8px;
          font-size: 18px;
          color: #2a2a2a;
        }
        p{
          width: 705px;
          padding-top: 11px;
          font-size: 14px;
          color: #ababab;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          
        }
      }
    }
  }
}
</style>
<style lang="scss">
.course-list-page {
  .list-box {
    .el-pagination {
      text-align: center;
      margin-top: 30px;
      .btn-prev,
      .btn-next {
        background-color: #fff !important;
        border: 1px solid #66a4ff;
        border-radius: 3px !important;
        padding: 5px 10px !important;
        box-sizing: border-box !important;
        &:hover {
          color: #66a4ff;
        }
        span {
          line-height: 1;
        }
      }
      .el-pager {
        li {
          font-weight: normal;
        }
      }
    }
  }
}
</style>
